// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.phd_students {
    border: none;
    width: auto;
}

.phd_students tr {
    background-color: #FFFFFF !important; 
}

.phd_students p {
    margin-bottom: 0;
}