@import
  "minima/skins/classic",
  "minima/initialize";

@media screen and (max-width: 500px) {
  .personal-info tr {
    display: inline-flex;
    flex-direction: column;
  }
}

.personal-info {
  border: none;
  display: block;
}

.personal-info img {
  width: 170px;
  max-width: fit-content;
}

// .personal-info p {
  // white-space: nowrap;
// }

tr td {
  border: none
}

h2 {
  margin-top: 0 !important;
}

h3 {
  margin-top: 0 !important;
}